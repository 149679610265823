import type { GetStaticPropsContext } from "next";
import { HeadSeo } from "@calcom/ui";
import PageWrapper from "@components/PageWrapper";
import { ssgInit } from "@server/lib/ssg";

export default function Custom401() {
  return (
    <>
      <HeadSeo
        title={"401 - Unauthorized"}
        description={"You are not authorized to access this page."}
        nextSeoProps={{
          nofollow: true,
          noindex: true,
        }}
      />
      <div className="bg-default min-h-screen px-4" data-testid="404-page">
        <main className="mx-auto max-w-xl pt-16 pb-6 sm:pt-24">
          <>
            <div className="text-center">
              <p className="text-emphasis text-sm font-semibold uppercase tracking-wide">
                {"ERROR 401"}
                <br />
                {"You are not authorized to access this page."}
              </p>
            </div>
          </>
        </main>
      </div>
    </>
  );
}

Custom401.PageWrapper = PageWrapper;

export const getStaticProps = async (context: GetStaticPropsContext) => {
  const ssr = await ssgInit(context);

  return {
    props: {
      trpcState: ssr.dehydrate(),
    },
  };
};
